<template>
    <div id="content" class="">
        <div class="bar-actions"></div>
        <div class="designer-container">
            <div class="designer">
                <div class="tags">
                    <div class="tag" v-for="(tag, i) in tags" :key="i" @click="select('[' + tag + ']')">[{{ tag }}]</div>
                </div>
                <textarea col="10" v-model="data"></textarea>
                <textarea col="10" v-model="preview"></textarea>
                <div class="button" @click="format()">Change format</div>
                <div class="button" @click="copy()">Copy</div>
                <div class="fields">
                    <div class="field" v-for="(field, i) in preview_obj.fields" :key="i">
                        <select>
                            <option value="text" :selected="field.type == 'text'">Texto</option>
                            <option value="image" :selected="field.type == 'image'">Imagen</option>
                            <option value="barcode" :selected="field.type == 'barcode'">Barcode</option>
                            <option value="days" :selected="field.type == 'days'">Días semana</option>
                        </select>
                        <input type="text" :value="field.value" @input="updatePreview($event.target.value, i)" />

                        <select>
                            <option value="l" :selected="field.align == 'l'">Left</option>
                            <option value="c" :selected="field.align == 'c'">Center</option>
                            <option value="r" :selected="field.align == 'r'">Right</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="preview">
                <Preview :maximized="true" :data="previewCalc(preview_obj)"></Preview>
            </div>
        </div>
    </div>
</template>

<script>
import Preview from '@/components/domain/label/preview'

export default {
    name: 'ConfigTemplate',
    components: { Preview },
    props: {},
    data() {
        return {
            k: 1,
            tags: ['print_date', 'print_date_day', 'print_date_time', 'date', 'date_day', 'date_time', 'prep_date', 'prep_date_day', 'prep_date_time', 'caducidad', 'state', 'quantity', 'employee', 'note'],
            preview: JSON.stringify(
                {
                    width: 60,
                    height: 60,
                    fields: [
                        {
                            type: 'image',
                            field: 1,
                            value: 'https://www.travelclub.es/imagenes/NW_conseguirpuntos/logos/compra_online/Logo_telepizza2.gif',
                            width: 30
                        },
                        {
                            type: 'image',
                            field: 1,
                            value: 'https://my.andyapp.io/img/alex_carita_07.svg',
                            width: 20
                        },
                        {
                            type: 'text',
                            field: 2,
                            value: '',
                            width: 30
                        },
                        {
                            type: 'text',
                            field: 2,
                            value: '[employee]',
                            width: 20
                        },
                        {
                            type: '',
                            value: '',
                            width: 100,
                            height: 20
                        },
                        {
                            type: 'text',
                            field: 3,
                            value: '[product]',
                            align: 'c',
                            style: 'b',
                            width: 100,
                            height: 60
                        },
                        {
                            type: 'text',
                            field: 4,
                            value: 'CAD 1ª',
                            align: 'l',
                            style: '',
                            width: 40
                        },
                        {
                            type: 'text',
                            field: 4,
                            value: '[caducidad]',
                            align: 'l',
                            style: '',
                            width: 60
                        },
                        {
                            type: 'text',
                            field: 4,
                            value: 'USO',
                            align: 'l',
                            style: '',
                            width: 40
                        },
                        {
                            type: 'text',
                            field: 4,
                            value: '[prep_date]',
                            align: 'l',
                            style: '',
                            width: 60
                        },
                        {
                            type: 'text',
                            field: 4,
                            value: 'DESCONG.',
                            align: 'l',
                            style: '',
                            width: 40
                        },
                        {
                            type: 'text',
                            field: 4,
                            value: '[print_date]',
                            align: 'l',
                            style: '',
                            width: 60
                        },
                        {
                            type: 'barcode',
                            field: 5,
                            value: '1632129024',
                            align: 'l',
                            style: '',
                            width: 100,
                            height: 60
                        },
                        {
                            type: 'days',
                            field: 6,
                            value: '1632129024',
                            width: 100,
                            height: 100
                        }
                    ]
                },
                null,
                '\t'
            ),
            data: JSON.stringify({ 1: '[date_d1]', 2: '[date_d2]', 3: '[date_d3]', 4: '[date_d4]', 5: '[date_d5]', 6: '[date_d6]', 7: '[date_d7]', 8: '{employee}', 9: '[product]', 10: 'FECHA APERTURA', 11: '[print_date]', 12: 'CAD PRIMARIA: [caducidad_day]', 13: '[caducidad_timestamp]', 14: 'CADUCIDAD: [date]', 15: '[date_timestamp]', 16: '[note]' }),
            data_obj: { 1: '[date_d1]', 2: '[date_d2]', 3: '[date_d3]', 4: '[date_d4]', 5: '[date_d5]', 6: '[date_d6]', 7: '[date_d7]', 8: '{employee}', 9: '[product]', 10: 'FECHA APERTURA', 11: '[print_date]', 12: 'CAD PRIMARIA: [caducidad_day]', 13: '[caducidad_timestamp]', 14: 'CADUCIDAD: [date]', 15: '[date_timestamp]', 16: '[note]' },
            mode: true
        }
    },
    computed: {
        preview_obj() {
            return JSON.parse(this.preview)
        }
    },
    created() {
        this.load()
    },
    watch: {
        $route: 'load'
    },
    methods: {
        load() {
            var self = this
        },
        format() {
            if (this.mode) {
                this.preview = JSON.stringify(JSON.parse(this.preview))
            } else {
                this.preview = JSON.stringify(JSON.parse(this.preview), null, '\t')
            }

            this.mode = !this.mode
        },
        copy() {
            const el = document.createElement('textarea')
            el.value = JSON.stringify(JSON.parse(this.preview))
            document.body.appendChild(el)
            el.select()
            document.execCommand('copy')
        },
        select(tag) {
            const el = document.createElement('textarea')
            el.value = tag
            document.body.appendChild(el)
            el.select()
            document.execCommand('copy')
        },
        updatePreview(newValue, index) {
            this.preview_obj.fields[index].value = newValue
            this.preview = JSON.stringify(this.preview_obj)
        },
        previewCalc(preview) {
            var self = this
            var newPreview = Object.assign(preview)

            for (var k in newPreview.fields) {
                // console.log('K -> ', k, typeof k)
                // console.log('f_id -> ', newPreview.fields[k].f_id)
                if (newPreview.fields[k].f_id) console.log('f_id -> ', JSON.parse(self.data)[newPreview.fields[k].f_id])
                // console.log('value -> ', newPreview.fields[k].value)
                newPreview.fields[k].value = newPreview.fields[k].f_id ? JSON.parse(self.data)[newPreview.fields[k].f_id] : newPreview.fields[k].value
            }

            return newPreview
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#content {
    padding-top: 100px;

    .designer-container {
        padding: 20px 50px;

        .designer {
            width: calc(100% - 600px);
            display: inline-block;
            padding: 25px;
            float: left;

            .tag {
                display: inline-block;
                background: #c888c3;
                padding: 5px 5px;
                text-align: center;
                margin-right: 5px;
                margin-bottom: 5px;
                border-radius: 3px;
                font-size: 12px;
                cursor: pointer;
            }

            textarea {
                width: 100%;
                height: 200px;
                background-color: #fff !important;
                padding: 25px;
                margin-top: 5px;
            }

            .button {
                display: inline-block;
                background: #fff;
                padding: 5px 10px;
                text-align: center;
                margin-right: 5px;
                border-radius: 3px;
                font-size: 14px;
                cursor: pointer;
            }
            .fields {
                margin-top: 20px;

                .field {
                    padding: 10px;
                    margin-bottom: 5px;
                    select,
                    input {
                        background-color: #fff !important;
                        margin-right: 10px;
                        height: 30px;
                        padding: 5px 10px;
                    }
                    &.barcode,
                    &.days {
                        margin: 5px 0;
                    }
                }
            }
        }

        .preview {
            padding: 25px;
            width: 500px;
            display: inline-block;
            float: left;
            position: fixed;
        }
    }
}
</style>
